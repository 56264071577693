
<template>
  <div>
      <v-card>
        <v-card-title style="word-break: break-word;">
          <div class="text-overline">{{item.title}}<span style="color: red;">{{item.required ? '*' : ''}}</span></div>
        </v-card-title>
        <v-card-text style="word-break: break-word;">
          <div class="text-caption">{{item.description}}</div>
            <v-rating
              empty-icon="$ratingFull"
              length="6"
              hover
              size="32"
              v-model="answer"
            >
              <template v-slot:item="props">
                <v-btn @click="props.click" icon :class="$vuetify.breakpoint.xs ? 'd-flex mt-3 ml-auto mr-auto' : '' ">
                  <v-icon large style="left: 5px">
                    {{ props.isFilled ? 'mdi-star' : 'mdi-star-outline' }}
                  </v-icon>
                  <span style="position: relative; top: -25px; right: 12px">{{props.index}}</span>
                </v-btn>
              </template>
            </v-rating>
            <v-input :value="answer" :rules="requiredRatingItem"/>
        </v-card-text>
      </v-card>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import validations from '@/mixins/validations';

export default {
  name: 'RatingItem',
  mixins: [
    validations
  ],
  props: {
    item: {
      type: Object,
      default: {}
    },
    read_only: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    answer: null,
    rating: 0
  }),
  methods: {
    setItem(Nv){
      let new_done_item = {
        index_order: this.item.index_order || 0,
        item_id: this.item.id,
        title: this.item.title,
        answer: this.answer
      }
      let has_item_id = this.done_items_attributes.findIndex(done_item => {
        return done_item.item_id == this.item.id;
      }) < 0 ? false : true
      if(has_item_id){
        this.editInDoneItemsAttributes(new_done_item)
      }else{
        this.addToDoneItemsAttributes(new_done_item)
      }
    },
    ...mapActions({
      addToDoneItemsAttributes: 'DoneForm/addToDoneItemsAttributes',
      editInDoneItemsAttributes: 'DoneForm/editInDoneItemsAttributes'
    })
  },
  computed: {
    ...mapState({
      done_items_attributes: state=> state.DoneForm.done_items_attributes,
    }),
    iconValidationColor(){
      if(this.$refs.form.validate()){

      }
    }
  },
  watch: {
    answer(Nv){
      this.setItem(Nv)
    }
  },
}
</script>

<style scoped>

</style>